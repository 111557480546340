
import React, { useState, useEffect } from "react"

import { useLocation } from "@reach/router"
import queryString from "query-string"
import Banner from "./banner"

import { resend_confirmation_email, confirm, getSession } from "../../services/auth"
import { navigate } from "../../services/routes" // "gatsby"
import ClientOnly from "../client_only"
import routes from "../../services/routes"

const ConfirmNoParams = () => {
	const [state, setState] = useState("unsent");
	const session = getSession();
	if (!session.user) {
		navigate(routes.login);
	}
	const resend = async (event) => {
		event.preventDefault();
		if (state==="unsent") {
			setState("sending");
			if (session.user) {
				// this should always be true, as a check was performed earlier (see above)
				await resend_confirmation_email(session.user.email);
			}
			setState("sent");
		}
	}

	return (
	<>
		<Banner title="Confirm Email" />
		<div style={{backgroundColor:"white", margin:"20px", padding:"5px", textAlign:"center"}}>
			<h4> </h4>To finish creating your account, you need to confirm your email address.
			<br /> You should have received an email with a link to click to activate your account (
			<a id="send_confirm_email" href="#" onClick={resend}>
				resend
			</a>
			).
			<br />
			<br />
			{ state==="sent" &&
				<h4 id="email_sent">
					A confirmation email has been sent.
				</h4>
			}
		</div>
	</>
	);
}

const ConfirmWithParams = ({email, emailKey}) => {
	const [state, setState] = useState("confirmation not sent");
	const resend = async (event) => {
		event.preventDefault();
		if (state!=="resend request sent") {
			setState("resend request sent");
			await resend_confirmation_email();
		}
	}
	useEffect(() => {
		(async () => {
			if (state==="confirmation not sent") {
				setState("confirmation sent");
				let result = await confirm(email, emailKey);
				if (result.success) {
					navigate(routes.account.index);
				} else {
					console.log(result);
					setState("confirmation failed");
				}
			}
		})();
	});
	return (
		<>
			<Banner title="Confirm Email" />
			<div style={{backgroundColor:"white", margin:"20px", padding:"5px", textAlign:"center"}}>
				<h4> </h4>Confirming your email address...
				<br></br>
				<br></br>
				{ state==="confirmation failed" &&
					<>
						<h4 id="email_sent">
							Confirmation failed.
						</h4>
						<br /> Your confirmation key may have expired. You can request a new key (
							<a id="send_confirm_email" href="#" onClick={resend}>
								resend
							</a>
							).
					</>
				}
				{ state==="resend request sent" &&
					<>
						<h4 id="email_sent">
							A new confirmation email has been requested.
						</h4>
						<br /> Please check your inbox.
					</>
				}
			</div>
		</>
	);
}

export default () => {
	const location = useLocation();
		// TODO support old-style urls of the form /confirm/:key
	if (location.pathname==="/confirm" || location.pathname==="/confirm/") {
		const queryParams = queryString.parse(location.search);
		if ("key" in queryParams && "email" in queryParams) {
			// TODO handle in a better way (move ClientOnly farther down the tree)
			return (
				<ClientOnly>
					<ConfirmWithParams email={queryParams.email} emailKey={queryParams.key} />
				</ClientOnly>
			);
		}
		// TODO handle in a better way (move ClientOnly farther down the tree)
		return (
			<ClientOnly>
				<ConfirmNoParams />
			</ClientOnly>
		);
	}
	return (
		<p>Invalid Confirm Url</p>
	);
};
